<template>
  <default-layout>
    <v-container id="refund-initiator-add" fluid tag="section">
      <refund-initiator-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const RefundInitiatorForm = () =>
  import('@/components/refund-management/initiator/refund/form.vue');

export default {
  name: 'refund-initiator-add',
  components: {
    DefaultLayout,
    RefundInitiatorForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('refundInitiator/form/restoreInitialState');
    this.$store.commit('refundInitiator/form/SET_FORM_TYPE', 'ADD');
    await this.$store.dispatch('refundInitiator/form/fetchBaseData');
  },
};
</script>
